<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="3">
        <b-form-input v-model="search" size="sm" placeholder="Suche nach Hostname..." @input="emitSearchChanged"/>
      </b-col>
      <b-col cols="5">
        <b-form-checkbox v-model="filterOptions.tasks_finished" inline>
          <b-badge class="mr-2" :variant="variantSuccess">Abgeschlossen</b-badge>
        </b-form-checkbox>
        <b-form-checkbox v-model="filterOptions.tasks_error" inline>
          <b-badge class="mr-2" :variant="variantDanger">Fehler</b-badge>
        </b-form-checkbox>
        <b-form-checkbox v-model="filterOptions.tasks_running" inline>
          <b-badge class="mr-2 progress-bar-striped progress-bar-animated" variant="primary">Laufend</b-badge>
        </b-form-checkbox>
        <b-form-checkbox v-model="filterOptions.tasks_pending" inline>
          <b-badge class="mr-2 progress-bar-striped" variant="grey-700">Geplant</b-badge>
        </b-form-checkbox>
      </b-col>
      <b-col cols="2">
        <b-form-checkbox v-model="filterOptions.user_only" inline>
          <b-badge id="tt-user-only" class="mr-2" variant="primary">Nur selbst erstellte Aktionen</b-badge>
        </b-form-checkbox>
        <b-tooltip target="tt-user-only">Nur Aktionen des eigenen Benutzer werden angezeigt</b-tooltip>
      </b-col>
      <b-col cols="auto" class="ml-auto">
        <b-button size="sm" variant="primary" class="mb-2" @click="emitFilterOptions">
          <b-icon-search/>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="3">
        <b-input-group size="sm">
          <b-form-input v-model="filterOptions.time_start" type="text" placeholder="Startzeitpunkt"
                        @input="inputChangeStart"/>
          <b-input-group-append>
            <b-form-datepicker v-model="datepickerDateStart" size="sm" button-only dropleft locale="de"
                               @input="datepickerChangeStart"/>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="3">
        <b-input-group size="sm">
          <b-form-input v-model="filterOptions.time_end" type="text" placeholder="Endzeitpunkt"
                        @input="inputChangeEnd"/>
          <b-input-group-append>
            <b-form-datepicker v-model="datepickerDateEnd" size="sm" button-only dropleft locale="de"
                               @input="datepickerChangeEnd"/>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="1" class="pl-0">
        <b-button size="sm" variant="outline-danger" @click="clearDatepicker"><b-icon-trash/></b-button>
      </b-col>
      <b-col cols="auto" class="ml-auto" align-self="center">
        <span>Anzahl der Aktionen: {{ filteredCount }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {mapGetters} from "vuex";

export default {
  name: "TaskFilter",
  props: ['filteredCount'],
  data() {
    return {
      search: '',
      filterOptions: {
        time_start: null,
        time_end: null,
        user_only: false,
        tasks_pending: true,
        tasks_running: true,
        tasks_finished: true,
        tasks_error: true
      },
      datepickerDateStart: null,
      datepickerDateEnd: null,
    }
  },
  methods: {
    clearDatepicker() {
      this.filterOptions.time_start = null
      this.filterOptions.time_end = null
      this.datepickerDateStart = null
      this.datepickerDateEnd = null
    },
    emitSearchChanged() {
      this.$emit('search-changed', this.search)
    },
    emitFilterOptions() {
      this.$emit('filter-options', this.filterOptions)
    },
    inputChangeStart(event) {
      if(dayjs(event, 'DD.MM.YYYY', true).isValid()) {
        this.datepickerDateStart = dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
      }
    },
    datepickerChangeStart(event) {
      if(dayjs(event, 'YYYY-MM-DD', true).isValid()) {
        this.filterOptions.time_start = dayjs(event).format('DD.MM.YYYY')
      }
    },
    inputChangeEnd(event) {
      if(dayjs(event, 'DD.MM.YYYY', true).isValid()) {
        this.datepickerDateEnd = dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
      }
    },
    datepickerChangeEnd(event) {
      if(dayjs(event, 'YYYY-MM-DD', true).isValid()) {
        this.filterOptions.time_end = dayjs(event).format('DD.MM.YYYY')
      }
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  },
  mounted() {
    if(this.$route.query && this.$route.query.hostname) {
      this.search = this.$route.query.hostname
    }
    this.emitSearchChanged()
    this.emitFilterOptions()
  }
}
</script>

<style lang="scss" scoped>
</style>
